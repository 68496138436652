.ape-wrapper[data-device-type="mweb"].ape-wrapper[data-page-type="ABsearch"], .ape-wrapper[data-device-type="mweb"].ape-wrapper[data-page-type="search"], .ape-wrapper[data-device-type="mshop"].ape-wrapper[data-page-type="ABsearch"], .ape-wrapper[data-device-type="mshop"].ape-wrapper[data-page-type="search"] {
  border: 1px solid #ccc;
  display: grid;
}

.ape-wrapper[data-device-type="mweb"].ape-wrapper[data-page-type="ABsearch"] .ape-feedback, .ape-wrapper[data-device-type="mweb"].ape-wrapper[data-page-type="search"] .ape-feedback, .ape-wrapper[data-device-type="mshop"].ape-wrapper[data-page-type="ABsearch"] .ape-feedback, .ape-wrapper[data-device-type="mshop"].ape-wrapper[data-page-type="search"] .ape-feedback {
  order: -1;
  width: 100%;
  text-align: left !important;
  height: 24px !important;
  margin-top: 0 !important;
  padding-left: 8px !important;
}

.ape-placement[data-slot-name="auto-left-advertising-2"], .ape-placement[data-slot-name="fresh-ad-left-2"], .ape-placement[data-slot-name="wfm-ad-left-2"] {
  display: none;
}

.ape-placement[data-slot-name="auto-left-advertising-2"].show, .ape-placement[data-slot-name="fresh-ad-left-2"].show, .ape-placement[data-slot-name="wfm-ad-left-2"].show {
  display: block;
}

.ape-wrapper[data-weblabs~="ADPT_SF_GWATF_ROUNDED_CORNERS_1036948"].ape-wrapper[data-page-type="gateway"][data-slot-name="atf"].ape-wrapper[data-device-type="mweb"] .ape-placement, .ape-wrapper[data-weblabs~="ADPT_SF_GWATF_ROUNDED_CORNERS_1036948"].ape-wrapper[data-page-type="gateway"][data-slot-name="atf"].ape-wrapper[data-device-type="mshop"] .ape-placement {
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  overflow: hidden;
  height: inherit !important;
  max-width: 100% !important;
}

.ape-wrapper[data-weblabs~="ADPT_SF_GWATF_ROUNDED_CORNERS_1036948"].ape-wrapper[data-page-type="gateway"][data-slot-name="atf"].ape-wrapper[data-device-type="mweb"] .ape-placement .border-enforcement, .ape-wrapper[data-weblabs~="ADPT_SF_GWATF_ROUNDED_CORNERS_1036948"].ape-wrapper[data-page-type="gateway"][data-slot-name="atf"].ape-wrapper[data-device-type="mshop"] .ape-placement .border-enforcement {
  display: none !important;
}

.ape-wrapper[data-weblabs~="ADPT_SF_GWATF_ROUNDED_CORNERS_1036948"].ape-wrapper[data-page-type="gateway"][data-slot-name="atf"].ape-wrapper[data-device-type="mweb"] .ape-placement img.ad-background-image, .ape-wrapper[data-weblabs~="ADPT_SF_GWATF_ROUNDED_CORNERS_1036948"].ape-wrapper[data-page-type="gateway"][data-slot-name="atf"].ape-wrapper[data-device-type="mshop"] .ape-placement img.ad-background-image {
  transform: scaleX(1.01)scaleY(1.07)translate(-49.5%) !important;
}

.ape-placement[data-weblabs~="ADPT_SF_SERVER_SIDE_SPONSORED_LABEL_998980"] .ape-feedback {
  text-align: right;
  height: 20px;
  margin-top: 2px;
  padding: 0;
  position: relative;
}

.ape-placement[data-weblabs~="ADPT_SF_SERVER_SIDE_SPONSORED_LABEL_998980"] .ape-feedback[id*="hero-quick-promo_Desktop_placement"] {
  text-align: left;
}

.ape-wrapper[data-weblabs~="ADPT_SF_SERVER_SIDE_SPONSORED_LABEL_998980"] .ape-feedback {
  text-align: right;
  height: 20px;
  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 5px;
  position: relative;
}

.ape-wrapper[data-weblabs~="ADPT_SF_SERVER_SIDE_SPONSORED_LABEL_998980"] .ape-feedback:not([id*="-ilm"]) {
  max-width: min(100vh, 100vw);
}

.ape-wrapper[data-weblabs~="APM_STORES_JPS_JRS_SAFEFRAME_A11Y_SPONSORED_LABEL_1206350"][data-device-type="mweb"] .ape-feedback, .ape-wrapper[data-weblabs~="APM_STORES_JPS_JRS_SAFEFRAME_A11Y_SPONSORED_LABEL_1206350"][data-device-type="mshop"] .ape-feedback {
  min-height: 20px;
  height: auto !important;
  line-height: normal !important;
}

.ape-wrapper[data-weblabs~="APM_STORES_JPS_JRS_SAFEFRAME_A11Y_SPONSORED_LABEL_1206350"][data-device-type="mweb"] .ape-feedback span, .ape-wrapper[data-weblabs~="APM_STORES_JPS_JRS_SAFEFRAME_A11Y_SPONSORED_LABEL_1206350"][data-device-type="mshop"] .ape-feedback span {
  align-items: center;
  display: inline-flex;
  font-size: 1.1rem !important;
}

.ape-wrapper[data-weblabs~="APM_STORES_JPS_JRS_SAFEFRAME_A11Y_SPONSORED_LABEL_1206350"][data-device-type="mweb"] .ape-feedback span b, .ape-wrapper[data-weblabs~="APM_STORES_JPS_JRS_SAFEFRAME_A11Y_SPONSORED_LABEL_1206350"][data-device-type="mshop"] .ape-feedback span b {
  margin: 0 0 0 3px !important;
}
/*# sourceMappingURL=apePlacements-1.50.26d89f5a.css.map */
