// @TODO(calwlai): When we are fully dialed up on ADPT_SF_SERVER_SIDE_SPONSORED_LABEL_998980, we can move styles to
// css/apePlacements/base/feedback.scss

// Style server side sponsored label desktop
.ape-placement[data-weblabs~='ADPT_SF_SERVER_SIDE_SPONSORED_LABEL_998980'] {
    .ape-feedback {
        position: relative;
        height: 20px;
        padding: 0px;
        margin-top: 2px;
        text-align: right;
    }

    .ape-feedback[id*='hero-quick-promo_Desktop_placement'] {
        text-align: left;
    }
}

// Style server side sponsored label mobile
.ape-wrapper[data-weblabs~='ADPT_SF_SERVER_SIDE_SPONSORED_LABEL_998980'] {
    .ape-feedback {
        position: relative;
        height: 20px;
        text-align: right;
        padding: 0 5px;
        margin-top: 2px;
        margin-left: auto;
        margin-right: auto;
    }

    .ape-feedback:not([id*='-ilm']) {
        max-width: min(100vh, 100vw);
    }
}
