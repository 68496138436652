/**
 * Alot (or all) of this code can be cleaned up after resolution of this ticket:
 * https://t.corp.amazon.com/V1731409929
 *
 */
.ape-wrapper[data-weblabs~='APM_STORES_JPS_JRS_SAFEFRAME_A11Y_SPONSORED_LABEL_1206350'][data-device-type='mweb'],
.ape-wrapper[data-weblabs~='APM_STORES_JPS_JRS_SAFEFRAME_A11Y_SPONSORED_LABEL_1206350'][data-device-type='mshop'] {
    .ape-feedback {
        height: auto !important;
        min-height: 20px;
        line-height: normal !important;

        span {
            // Use rem of 1.1em based on 10px root to get 11px equivalent.
            // Code reference: https://code.amazon.com/packages/AmazonUIBaseCSS/blobs/acdd7b754ee0c425bb522f26b2ca0ce5e7b7f661/--/base/src/_base_touch.device_type=mobile.scss#L2
            // Note that 62.5% of the default 16px = 10px.
            font-size: 1.1rem !important;

            // properly align sponsored label, particularly when text zoom is active
            display: inline-flex;
            align-items: center;

            b {
                margin: 0 0 0 3px !important;
            }
        }
    }
}
